import {jsx as _jsx} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {Fragment} from "react";
import {ColorBlock, colors} from "@/feature-docs/ThemeComponents.jsx";
function _createMdxContent(props) {
  return _jsx(Fragment, {
    children: Object.keys(colors).map((key, i) => _jsx(ColorBlock, {
      title: key,
      colors: colors[key]
    }, i))
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
